.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  align-items: center;
  max-width: 100%;
  height: 100%;
}
.container .category_tab {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
/* project gallery */
.projects {
  width: 100%;
}
.projects .flex_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-items: flex-start;
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 30px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0px 20px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 575.98px) {
  .projects .flex_container {
    gap: 20px 20px;
    flex-direction: column;
    width: 100%;
  }
}
