.privacy-policy-container {
  padding: 10px 80px 20px;
}
.privacy-policy-container > h1 {
  color: #ddcc30;
}
.privacy-policy-container .privacy-policy-details {
  padding: 2rem;
  min-height: 60vh;
  color: white;
}
@media screen and (max-width: 768px) {
  .privacy-policy-container {
    padding: 10px 20px 20px;
  }
}
