.about-me {
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  margin-bottom: 100px;
}
/* Content */
.content-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.content-wrapper .profile-image {
  flex: 1;
  display: inline-flex;
}
.content-wrapper .profile-image img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.content-wrapper .description-container {
  flex: 1;
  background: radial-gradient(
    108.65% 129.08% at 0% 0.09%,
    rgba(255, 253, 253, 0.1) 0%,
    rgba(78, 77, 77, 0.297898) 27.87%,
    rgba(34, 34, 34, 0.62705) 46.57%,
    #000000 78.94%
  );
  border: 1px solid #1c1c21;
  border-radius: 16px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.content-wrapper .description-container .about-content h2 {
  text-transform: uppercase;
}
.content-wrapper .description-container .about-content p {
  text-align: justify;
}

.description-container .social-media-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.description-container .social-media-wrapper .follow-label {
  text-align: left;
  text-transform: uppercase;
}
.description-container .social-media-wrapper .social-media {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.description-container .social-media-wrapper .social-media svg {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .about-me {
    padding: 0 30px;
    margin-bottom: 80px;
  }
  .description-container .social-media svg {
    height: 35px;
    width: 35px;
  }
}
@media screen and (max-width: 768px) {
  .about-me {
    padding: 0 20px;
    margin-bottom: 60px;
  }
  .about-me .content-wrapper {
    flex-direction: column;
  }
  .description-container .social-media svg {
    height: 29px;
    width: 29px;
  }
  .content-wrapper .profile-image img {
    max-height: 400px;
  }
}
