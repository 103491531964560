.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  gap: 50px;
  align-items: center;
  max-width: 100%;
  height: 100%;
}
.services {
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.service-card {
  font-size: 100px;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0px 20px;
  }
  .services {
    padding: 20px;
    gap: 20px;
    overflow: hidden;
  }
}
