.container {
  padding: 0 80px;
  border-top: 1px solid #1c1c21;
  background: url('../../assets/strip.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  padding: 40px 50px;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: #1c1c21;
  background-color: black;
}
.footer .footer-logo-wrapper {
  flex: 1;
  min-width: 30%;
  display: flex;
  flex-direction: column;
}
.footer .footer-logo-wrapper .footer-logo {
  height: 150px;
  height: 150px;
  object-fit: contain;
}
.footer .social-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer .social-wrapper .social-icons {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.footer .social-wrapper .social {
  text-transform: capitalize;
}
.footer .social-wrapper .social-icons svg {
  cursor: pointer;
}
.footer .contact-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer .contact-wrapper .phone-wrapper,
.address-wrapper,
.email-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.footer .contact-wrapper svg {
  width: 40px;
  height: 40px;
}
.footer .contact-wrapper a {
  text-decoration: none;
}
.footer-legals-and-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  background: #1c1c21;
  border-top: 1px solid #1c1c21;
  gap: 10px;
  flex-wrap: wrap;
  text-align: left;
}
.footer-legals-and-credits a {
  text-decoration: none;
}
.mervix {
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .footer {
    flex-direction: column;
    gap: 20px;
  }
  .footer-legals-and-credits {
    padding: 0 20px;
  }
}
