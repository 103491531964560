.milestone {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 80px;
  margin-bottom: 100px;
  gap: 30px;
}

@media screen and (max-width: 1024px) {
  .milestone {
    padding: 0px 30px;
    margin-bottom: 80px;
    justify-content: center;
    gap: 20px;
  }
}
@media screen and (max-width: 768px) {
  .milestone {
    padding: 0px 20px;
    margin-bottom: 60px;
    gap: 15px;
  }
}
