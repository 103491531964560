.terms-and-conditions-container {
  padding: 10px 80px 20px;
}
.terms-and-conditions-container > h1 {
  color: #ddcc30;
}
.terms-and-conditions-container .terms-and-conditions-details {
  padding: 2rem;
  min-height: 60vh;
  color: white;
}
@media screen and (max-width: 768px) {
  .terms-and-conditions-container {
    padding: 10px 20px 20px;
  }
}
