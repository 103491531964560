.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  align-items: center;
  max-width: 100%;
  height: 100%;
  gap: 20px;
}
.container .header_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  text-align: left;
}
.container .header_flex .title {
  width: 100%;
  text-transform: uppercase;
}
.container .header_flex h2 {
  text-transform: uppercase;
}
.container .image_container {
  width: 100%;
  height: 60vh;
  position: relative;
  overflow: hidden;
}
.container .image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.container .description {
  align-self: flex-start;
}
.container .description p,
.container .description ul,
.container .description ol,
.container .description li,
.container .description td,
.container .description th,
.container .description tr {
  color: white;
  background-color: black;
}
.container .share {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  gap: 16px;
}
.container .share .share_icon_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.container .gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 30px;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 30px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0px 20px;
    margin-bottom: 60px;
  }
  .container .image_container {
    height: 44vh;
  }
}
@media screen and (max-width: 575.98px) {
  .projects .flex_container {
    gap: 20px 20px;
    flex-direction: column;
    width: 100%;
  }
}
