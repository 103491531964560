.container {
  display: flex;
  margin-bottom: 100px;
  padding: 0 80px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.container .title {
  text-transform: uppercase;
  text-align: left;
}
.container .link a {
  display: inline-flex;
}
/* project gallery */
.projects {
  width: 100%;
}
.projects .flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-items: flex-start;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.flex-container::-webkit-scrollbar {
  display: none;
}
.projects .flex-container > div {
  width: 100%;
  align-items: stretch;
  flex: 0 0 33%;
}
@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0 20px;
  }
  .container .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .projects .flex-container {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .projects .flex-container > div {
    width: 100%;
    align-items: stretch;
    flex: 0 0 60%;
  }
}
@media screen and (max-width: 575.98px) {
  .projects .flex-container {
    gap: 10px 20px;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .projects .flex-container > div {
    flex: 0 0 70%;
  }
}
@media screen and (max-width: 390px) {
  .projects .flex-container > div {
    flex: 0 0 80%;
  }
}
