body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 14px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(209, 209, 209);
  /* border-radius: 100vw; */
  /* margin-block: .5rem; */
}

::-webkit-scrollbar-thumb {
  background: #d8c83d;
  /* border-radius: 100vw; */
  /* border: 5px solid red; */
}

::-webkit-scrollbar-thumb:hover {
  background: #ddcc30;
}
