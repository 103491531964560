.top {
  width: 100%;
  background-image: url('../../assets/camera.jpeg');
  background-position: center;
  background-size: cover;
  min-height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 100px;
}
.top .cover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 100%;
  text-align: center;
  text-transform: capitalize;
}

@media screen and (max-width: 800px) {
  .top {
    min-height: 25vh;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 428px) {
  .top {
    min-height: 20vh;
    margin-bottom: 60px;
  }
} /*# sourceMappingURL=Top.module.css.map */
