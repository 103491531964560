.green-button {
  background-color: #0034f0;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-size: 1rem;
  color: white;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.red-button {
  background-color: #f01400;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-size: 1rem;
  color: white;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.red-button:hover {
  background-color: #ff1d08;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: white;
  border: 1px solid gray;
  font-size: 1rem;
  color: black;
  cursor: pointer;
}
.upload-button .text {
  text-align: center;
  margin: 0rem 0.5rem 0rem 0rem;
}

.dark-blue-button {
  background: transparent;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  color: white;
  border-radius: 13px;
  border: 1px solid white;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: small-caps;
  color: #d9d9d9;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  letter-spacing: 1px;
  transition: background 1s ease;
  max-width: 10rem;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-blue-button:hover {
  background-color: white;
  color: black;
}

.action-button {
  background: #353356;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  color: white;
  border-radius: 13px;
  border: 1px solid white;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: small-caps;
  color: #d9d9d9;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  letter-spacing: 1px;
  transition: background 1s ease;
  max-width: 10rem;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background-color: #3d3b66;
  color: white;
}

.read-more-button {
  /* background: #ddcc30; */
  background: black;
  padding: 0.5rem 1rem 0.5rem 1rem;
  /* color: black; */
  color: white;
  border-radius: 13px;
  border: none;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  font-variant: small-caps;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  letter-spacing: 1px;
  transition: background 0.5s ease;
} /*# sourceMappingURL=Buttons.module.css.map */

.read-more-button:hover {
  background: white;
  color: black;
}
/* Call To Action Button Primary */
.cta-btn {
  height: auto;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 19px 50px;
  border-radius: 8px;
  gap: 10px;
  border: 1px solid;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  background: #000000;
  box-shadow: 1px 10px 10px 0px #ffffff40;
}

.cta-btn:hover {
  background: #1c1c21;
  border-color: #ffffff;
}

/* Media query for tablet */
@media screen and (max-width: 1024px) {
  .cta-btn {
    padding: 14px 20px;
    border-radius: 8px;
    gap: 5px;
    font-size: 16px;
    line-height: 21.86px;
  }
}

@media screen and (max-width: 768px) {
  .cta-btn {
    padding: 14px 20px;
    font-size: 12px;
    line-height: 19.12px;
  }
}

@media screen and (max-width: 575.98px) {
  .cta-btn {
    padding: 13px 20px;
    border-radius: 6px;
    gap: 2px;
    font-size: 10px;
    line-height: 17.1px;
  }
}
