.content {
  width: 100%;
  padding: 0px 80px;
  margin-bottom: 100px;
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 0px 30px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .content {
    padding: 0px 20px;
    margin-bottom: 60px;
  }
}
