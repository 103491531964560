.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  background: black;
  transition: all 1s ease;
  z-index: 5000;
  position: relative;
  border: 1px solid #1c1c21;
}
.nav .logo {
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.nav .logo .icon {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
}
.nav .logo .icon img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.nav .links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  transition: ease 1s all;
}
.nav .links ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  text-align: center;
  border-width: 0px 0px 1px 0px;
}
.nav .links ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  height: 100%;
  padding: 24px 40px 24px 40px;
  background: #000000;
  border-right: 1px solid #1c1c21;
}
.nav .links ul .active {
  color: #dfd25d;
}
.nav .links ul .passive {
  color: #ffffff;
}
.nav .links ul li a {
  font-size: 16px;
}

.menu {
  display: none;
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 1000px) {
  .nav {
    padding: 0rem 1rem 0rem 0rem;
  }
  .nav .links {
    display: none;
  }
  .nav .menu {
    display: flex;
    padding: 3px;
    margin: 0;
    cursor: pointer;
  }
  .mobile-nav {
    position: absolute;
    top: 0;
    right: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px 0px 20px;
    z-index: 1000;
    background: #1c1c21;
    height: 100vh;
    width: 100%;
  }
  .mobile-nav .mobile-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 76px;
    border-bottom: 1px solid #000000;
  }
  .mobile-nav .close-nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 1rem 0rem 0rem;
    cursor: pointer;
    transition: all 1s ease;
  }
  .mobile-nav ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .mobile-nav ul li {
    padding: 20px 0px 20px 0px;
    margin: 20px 0px 20px 0px;
    border-bottom: 1px solid #000000;
    cursor: pointer;
    width: 100%;
  }
  .mobile-nav ul li:hover {
  }
  .mobile-nav ul li a {
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
  }

  .mobile-active {
    border: "1px solid red";
    color: #ddcc30;
  }
  .mobile-active:hover {
    color: #dfd25d;
  }

  .mobile-passive {
    color: white;
  }
  .mobile-passive:hover {
    color: #dfd25d;
  }
}
@media screen and (max-width: 800px) {
  .logo .tagline {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 500px) {
  .logo {
    padding: 0.5rem !important;
    gap: 0.5rem;
  }
  .logo .tagline {
    font-size: 10px;
  }
} /*# sourceMappingURL=index.module.css.map */
