.card {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #e8e8e8 0%, rgba(255, 255, 255, 0.1) 76.23%);
  background: #1c1c21;
  box-shadow: 0px 2px 10px 6px #ffffff40;
  padding: 20px;
  border-radius: 16px;
}
.card h1 {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .card {
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .card {
    padding: 10px;
    box-shadow: 0px 0px 1px 2px #ffffff40;
  }
}
