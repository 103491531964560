.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  gap: 50px;
  align-items: center;
  max-width: 100%;
  height: 100%;
}
.container .header-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #1c1c21;
}
.header-wrapper .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  text-align: left;
}
.header-wrapper button {
  padding: 14px 50px;
}
.header-wrapper button span {
  display: inline-flex;
}
/* project gallery */
.projects {
  width: 100%;
}
.projects .flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-items: flex-start;
}
.flex-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0px 20px;
  }
  .container .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 575.98px) {
  .projects .flex-container {
    gap: 10px 20px;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}
