.card {
  flex: 1 0 calc(30% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  position: relative;
  gap: 30px;
  padding: 30px;
  background: #00000033;
  margin: 60px 10px;
  border-radius: 16px;
  backdrop-filter: blur(6px);
  max-width: 30%;
}
.card-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}
.card-title img {
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;
  height: 30px;
  width: 40px;
}
.card-title img > * {
  width: 100%;
  height: auto;
}
.card-title h3 {
  text-transform: capitalize;
}
/* content */
.card-content {
  display: flex;
  text-align: center;
}
.card-content p {
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Display only three lines */
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1024px) {
  .card-content p {
    -webkit-line-clamp: 7;
  }
}

@media screen and (max-width: 768px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 20px;
    margin: 0 0 0 0;
  }
  .card .card-content p {
    -webkit-line-clamp: 5;
  }
}
