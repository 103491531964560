.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 30px 30px;
  background-image: url('../../assets/shape.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #1c1c21;
  border-radius: 16px;
  height: 100%;
  margin: 0 10px;
}
.card .client-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 10px;
}
.card .client-image {
  height: 80px;
  width: 80px;
  border-radius: 50%; /* Create a circular shape */
  overflow: hidden; /* Hide any content outside the circle */
}
.client-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.client-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px 10px;
  text-align: left;
}
.client-testimonial {
  display: flex;
  text-align: left;
}
.client-testimonial p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Display only three lines */
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1024px) {
  .card {
    height: 345px;
  }
}
