.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  gap: 50px;
  align-items: center;
  max-width: 100%;
  height: 100%;
}
.container .header-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #1c1c21;
}
.header-wrapper .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  text-align: left;
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0px 30px;
  }
  .content-wrapper {
    gap: 10px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0px 20px;
  }
  .container .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .content-wrapper {
    justify-content: center;
  }
}
