.contactForm {
  flex: 70%;
  width: 100%;
  margin: 0 auto; /* Center the form horizontally */
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 30px;
  gap: 30px;
  border-radius: 16px;
  border: 1px solid;
  flex-wrap: wrap;
}

.formGroup {
  flex: 1 1 calc(45% - 30px);
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid #1c1c21;
  border-radius: 5px;
  background: var(--hover-color);
}
.message {
  width: 100%;
  flex: none;
}
.textArea {
  width: 100%;
  padding: 10px;
  background: #1c1c21;
  border-radius: 8px;
  resize: vertical; /* Allow vertical resizing */
  height: 150px;
  width: 100%;
}
.services {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  text-align: left;
}
.services .checkboxContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 5px;
  flex-wrap: wrap;
}
.services .checkboxContainer div {
  flex: 1;
}
.services .checkboxContainer label {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.checkbox {
  margin-right: 5px;
}

.submitButton {
  padding: 14px 50px 14px 50px;
  gap: 10px;
  border-radius: 8px;
  background: #000000;
  border: 1px solid;
  cursor: pointer;
}

.submitButton:hover {
  background: var(--hover-color);
  border: 1px solid;
}

.successMessage {
  color: green;
}
.errorMessage {
  color: red;
}
@media screen and (max-width: 1024px) {
  .contactForm {
    padding: 20px;
    gap: 20px;
  }
}
@media screen and (max-width: 768px) {
  .contactForm {
    padding: 15px;
    gap: 15px;
  }
}
@media screen and (max-width: 576px) {
  .contactForm {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
  .formGroup {
    flex: 1 1 100%;
    width: 100%;
  }
}
