.container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  padding: 0px 80px;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  height: 100%;
}
.container .header-wrapper {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.header-wrapper h1 {
  display: inline-flex;
  text-transform: uppercase;
}
.header-wrapper button {
  padding: 14px 50px;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0px 20px;
  }
  .container .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
