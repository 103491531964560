.image {
  flex: 1 1 calc(33% - 30px);
}
.image img {
  border-radius: 16px;
}
@media screen and (max-width: 768px) {
  .image {
    flex: 1 1 calc(50% - 30px);
  }
}

@media screen and (max-width: 575.98px) {
  .image {
    flex: 1 1 calc(100%);
  }
}
