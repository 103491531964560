.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid var(--hover-color);
  position: relative;
  flex: 0 1 calc(33.33% - 20px);
  margin-bottom: 20px;
}
.card .profile {
  height: auto;
  position: relative;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
  border-top: 10px solid var(--primary-color);
  width: 100%;
}
.card .profile img {
  display: block;
  width: 100%;
  height: 280px;
  object-fit: cover;
  margin: 10px 0px 0px 10px;
  object-position: top;
}
.card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 10px;
  text-align: center;
}
.card .content .name {
  font-weight: 700;
}
.card .content .socials {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .card {
    /* max-width: calc(30% - 20px); */
  }
  .card .profile img {
    height: 248px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    flex-basis: calc(100% - 20px);
  }
  .card .profile img {
    height: 288px;
  }
  .card .profile {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .card {
    max-width: calc(100% - 20px);
  }
  .card .profile {
    height: 293px;
  }
  .card .profile img {
    height: 280px;
  }
}
