.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 5rem;
	font-family: "Jost";
	background: #7b7e85;
	transition: all 1s ease;
	z-index: 5000;
	position: relative;

	.logo {
		flex: 2;
		color: white;
		height: 100%;
		padding: 0rem 0rem 0rem 3rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		padding: 0.5rem 0rem 0.5rem 0rem;
		.icon {
			width: 100%;
			height: 100%;
			flex: 1;
			overflow: hidden;
			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}
		.tagline {
			font-size: 24.63px;
			color: white;
			flex: 3;
			text-align: left;
		}
	}

	.links {
		flex: 3;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		transition: ease 1s all;

		ul {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			list-style: none;
			align-items: center;
			justify-content: space-around;
			margin: 0;
			padding: 0;
			text-align: center;
			li {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 300;
				a {
					font-size: 24.6297px;
				}
			}
		}
	}
}

.menu {
	display: none;
}
.mobile-nav {
	display: none;
}

@media screen and (max-width: 1000px) {
	.nav {
		padding: 0rem 1rem 0rem 0rem;
		.links {
			display: none;
		}
		.menu {
			display: flex;
			padding: 3px;
			margin: 0;
			cursor: pointer;
		}
	}
	.mobile-nav {
		position: absolute;
		top: 0;
		right: 0;
		display: flex !important;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 1rem 0rem 0rem 0rem;
		z-index: 1000;
		background: linear-gradient(
			176.58deg,
			#ddcc30 41.58%,
			rgba(228, 233, 239, 0) 93.2%
		);
		height: 100vh;
		width: 35%;

		.close-nav {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 0rem 1rem 0rem 0rem;
			cursor: pointer;
			transition: all 1s ease;
		}

		ul {
			list-style-type: none;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			padding: 0;

			li {
				padding: 1rem 0rem 1rem 0rem;
				margin: 1rem 0rem 1rem 0rem;
				width: 100%;
				cursor: pointer;
				&:hover {
					background-color: white;
					color: black;
				}

				a {
					font-size: 24.63px;
					width: 100%;
					height: 100%;
					display: block;
					text-align: center;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.logo {
		.tagline {
			font-size: 20px !important;
		}
	}
}

@media screen and (max-width: 500px) {
	.logo {
		padding: 0.5rem !important;
		gap: 0.5rem;
		.tagline {
			// display: none;
			font-size: 10px;
		}
	}
}
