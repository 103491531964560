.card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  gap: 15px;
  cursor: pointer;
}
/* image */
.card-image {
  flex: 1;
  width:100%;
}
.card-image img {
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
  height: 390px;
}
/* content */
/* .card-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
} */
.card-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 20px;
  height: auto;
  padding: 5px 12px 5px 12px;
  background: #00000080;
  justify-content: space-between;
  backdrop-filter: blur(2px);
}
.card-content .project-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.card-content .project-wrapper .title,
.date {
  flex: 1;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-content .link a {
  text-transform: uppercase;
  display: inline-flex;
}

@media screen and (max-width: 1024px) {
  .card-image img {
    height: 340px;
  }
}

@media screen and (max-width: 768px) {
  .card-image img {
    height: 300px;
  }
}

@media screen and (max-width: 575.98px) {
  .card {
    width: 100%;
    align-items: stretch;
  }
  /* .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 5px 12px 5px 12px;
    background: #00000080;
    justify-content: space-between;
    backdrop-filter: blur(2px);
  } */
}
