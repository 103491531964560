.container {
  width: 100%;
  height: 80vh;
  background-color: black;
  position: relative;
  padding: 0px;
  margin: 30px 0px 100px;
}
.banner {
  width: 100%;
  height: 100%;
}
.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner .video {
  width: 100%;
  height: 100%;
}
.swiper {
  width: 100%;
  height: 100%;
}
.project-wrapper {
  position: absolute;
  bottom: 10px; /* Distance from the bottom */
  right: 60px; /* Distance from the right */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #00000080;
  padding: 7px 20px 7px 20px;
  border-radius: 16px;
  gap: 50px;
}
.project-wrapper .project {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.project-wrapper .project .name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-wrapper .project .date {
  color: #e1e1e1;
}
.project-wrapper .link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.project-wrapper .link a {
  font-size: 14px;
  color: #f1f1f1;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 1024px) {
  .container {
    height: 65vh;
    margin: 30px 0px 80px;
  }
  .project-wrapper {
    bottom: 5px;
    right: 10px;
    gap: 30px;
    padding: 5px 10px 5px 10px;
  }
  .project-wrapper .project .name {
    font-size: 14px;
  }
  .project-wrapper .project .date {
    font-size: 12px;
  }
  .project-wrapper .link a {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 55vh;
    padding: 0px 30px 0px 30px;
    margin: 20px 0px 60px;
  }
  .project-wrapper {
    bottom: 3px;
    right: 5px;
    gap: 20px;
    padding: 3px 10px 3px 10px;
  }
  .project-wrapper .project .name {
    font-size: 12px;
  }
  .project-wrapper .project .date {
    font-size: 10px;
  }
  .project-wrapper .link a {
    font-size: 10px;
  }
}

@media screen and (max-width: 575.98px) {
  .container {
    height: 45vh;
    padding: 0px 20px 0px 20px;
  }
  .project-wrapper {
    bottom: 4px;
    right: 4px;
    gap: 12px;
    padding: 5px 10px 5px 10px;
  }
  .project-wrapper .project .name {
    font-size: 11px;
  }
  .project-wrapper .project .date {
    font-size: 9px;
  }
  .project-wrapper .link a {
    font-size: 9px;
  }
}
