.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: 0px 80px;
  gap: 50px;
  align-items: center;
  max-width: 100%;
  height: 100%;
}
.container .header-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #1c1c21;
}
.header-wrapper .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  text-align: left;
}
.header-wrapper .custom-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 8px 16px;
  border-radius: 100px;
  background: #000000;
  border: 2px solid #1c1c21;
}
.custom-navigation svg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.custom-navigation svg path:nth-child(2) {
  fill: #1c1c21;
}
.custom-navigation svg:hover {
  cursor: pointer;
  background-color: var(--hover-color);
}

/* Content */
.testimonials {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .container {
    margin-bottom: 80px;
    padding: 0px 30px;
  }
  .swiper-wrapper {
    --swiper-slide-per-view: 3;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 60px;
    padding: 0px 20px;
  }
  .container .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .header-wrapper .custom-navigation {
    display: none;
  }
  .swiper-wrapper {
    width: 100%;
    --swiper-slide-per-view: 2;
  }
}

@media screen and (max-width: 575.98px) {
  .swiper-wrapper {
    --swiper-slide-per-view: 1;
  }
}
