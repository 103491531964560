.about-me {
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  margin-bottom: 80px;
}
.container {
  background: black;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  gap: 50px;
}
.about-header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.about-header .title-wrapper {
  text-align: left;
  text-transform: uppercase;
  flex: 2;
}
.about-header .know-more {
  flex: 1;
  text-align: right;
}
/* Content */
.content-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.content-wrapper .profile-image {
  flex: 1;
  display: inline-flex;
}
.content-wrapper .profile-image img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
}
.content-wrapper .description-container {
  flex: 1;
  background: radial-gradient(
    108.65% 129.08% at 0% 0.09%,
    rgba(255, 253, 253, 0.1) 0%,
    rgba(78, 77, 77, 0.297898) 27.87%,
    rgba(34, 34, 34, 0.62705) 46.57%,
    #000000 78.94%
  );
  border: 1px solid #1c1c21;
  border-radius: 16px;
}
.content-wrapper .description-container .description-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 5px;
  padding: 30px;
}
.content-wrapper .description-container .description-wrapper .about-content {
  text-align: justify;
}
/* contact information */
.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  border-top: 1px solid #1c1c21;
}
.contact-wrapper .contact-header {
  display: flex;
  align-items: center;
  gap: 5px;
}
.contact-wrapper .contact-header object,
h3 {
  margin: 0;
}
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 25px;
  padding: 20px 5px;
}
.contact-info .top,
.bottom {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 25px;
}
.top .email-wrapper,
.phone-wrapper {
  flex: 1;
}
.email-wrapper a,
.phone-wrapper a {
  font-weight: 500;
  font-size: 18px;
}
.bottom .social-media-wrapper {
  flex: 1;
  padding: 8px 8px;
  gap: 15px;
  opacity: 0px;
  background: #070708;
  border: 1px solid #1c1c21;
  border-radius: 30px;
}
.bottom .social-media {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.bottom .social-media svg {
  flex: 1;
  cursor: pointer;
}
.bottom .cta-wrapper {
  flex: 2;
}
.bottom .cta-wrapper button {
  width: 100%;
  max-width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
}
@media screen and (max-width: 1024px) {
  .email-wrapper a,
  .phone-wrapper a {
    font-size: 15px;
  }
  .bottom .social-media svg {
    height: 35px;
    width: 35px;
  }
  .content-wrapper .description-container .description-wrapper {
    padding: 25px;
  }
}
@media screen and (max-width: 768px) {
  .about-me {
    padding: 20px 20px 0px 20px;
  }
  .about-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
  }
  /* Content */
  .content-wrapper {
    flex-direction: column;
  }
  .contact-info .top,
  .bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .email-wrapper a,
  .phone-wrapper a {
    font-size: 15px;
  }
  .bottom .social-media svg {
    height: 29px;
    width: 29px;
  }
  .bottom .cta-wrapper {
    flex: 1;
    width: 100%;
  }
  .content-wrapper .description-container .description-wrapper {
    padding: 17px;
  }
}
