.container {
  margin-bottom: 100px;
  padding: 0px 80px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.container .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url('../../../assets/mail.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left bottom;
}
.container .header {
  flex: 40%;
}
.container .form {
  flex: 60%;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 0px 30px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0px 20px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0px 15px;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .container .header {
    background-image: none;
  }
}
