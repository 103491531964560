.get-started {
  display: flex;
  flex-direction: row;
  gap: 33%;
  align-items: center;
  width: 100%;
  padding: 60px 55px 60px 55px;
  justify-content: space-between;
  background: linear-gradient(
    18deg,
    #000000 0%,
    #040404 17%,
    #333333 22%,
    #333333 33%,
    #000000 40%
  );
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #1c1c21;
  background-image: url("../../assets/abstract.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.get-started .column {
  flex-basis: 50%;
  justify-content: center;
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  text-transform: uppercase;
  text-align: left;
}

.info-section .name {
  position: relative;
}

.info-section .name h1 {
  text-align: left;
  z-index: 1;
}

.cta-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  position: relative;
}

.cta-title {
  text-align: left;
}
.services-container {
  background-color: #000000;
  position: relative;
}
.services-list {
  display: flex;
  list-style: none;
  padding: 0px 30px 0px 30px;
  margin: 0;
  background: linear-gradient(
    180deg,
    rgba(232, 221, 221, 0.538499) -24.19%,
    rgba(71, 70, 70, 0) 41.13%
  );
  border-width: 1px, 0px, 1px, 0px;
  border-style: solid;
  border-color: #1c1c21;
  justify-content: center;
}

.services-list .service {
  display: flex;
  flex: 1;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
}
/* button */
.cta-section button {
  padding: 14px 50px;
}
/* Media query for tablet */
@media screen and (max-width: 1024px) {
  .get-started {
    padding: 40px 30px 40px 30px;
  }
  .get-started h1 {
    margin: 0px 0px 5px;
  }
  .services-container span {
    font-size: 9px;
    line-height: 10px;
  }
}

/* Media query for mobile */
@media screen and (max-width: 768px) {
  .get-started {
    padding: 30px 20px 30px 20px;
  }
  .services-container span {
    font-size: 9px;
    line-height: 10px;
  }
  /* button */
  .cta-section button {
    padding: 14px 40px;
  }
}

/* Styles for extra small devices */
@media (max-width: 575.98px) {
  .get-started {
    flex-direction: column;
    padding: 25px 17px 25px 17px;
    align-items: flex-start;
  }
  /* button */
  .cta-section button {
    padding: 14px 30px;
  }
  .services-container {
    display: none;
  }
}
